.custom-button{
 width: 200px;
 height: 50px;
 background-color: transparent;
 color: white;
 border: 1px solid #0d82b8
 
}

.custom-button:not:disabled:hover{
    background-color: #01b358 !important;
    color: black;
    border: none;
   }

   .custom-button:hover{
    background-color: #01b358 !important;
    color: white !important;
    border: none;
   }

   .custom-button:disabled{
    color: white;
    border: 1px solid #0d82b8
   }

 
