.header{

  background-color: #001528;
  padding: 1px;
  color: white;
  z-index: 1;
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
    animation: smoothScroll 1s forwards;
}

.project-container{
  max-height: 30vh;
  overflow-y: scroll;
}

.table{
  height: 89vh;
  cursor: pointer;
}

.headerButton{
display: flex;
flex-direction: row-reverse;  

}

.headerButtons{
  margin: 5px;
}
.containerHeaders{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-right: 10px;
}

.graphView{
  height: 85vh;
  border: 1px solid rgb(197, 194, 194);
  width: auto;
}

.graph{
  height: 500px;
}

.exit 
{  width: 200px;
border-color: red;
color: red;
}

.close{
  width: 200px;
  border-color: red;
  color: red;
  position: absolute;
  right:20px
}